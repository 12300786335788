.chart-log-meeting-table {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 0.7fr 0.5fr;
  row-gap: 0.5rem;
  column-gap: 1rem;
  word-break: break-word;
}

.chart-log-meeting-action-table {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 1fr 0.8fr 0.7fr 0.5fr;
  row-gap: 0.3rem;
  column-gap: 1rem;
}

.chart-duration-action-table {
  display: grid;
  grid-template-columns: 40px 1fr 1.5fr 1.5fr 1fr;
  row-gap: 0.5rem;
  column-gap: 1rem;
  position: relative;
}

.chart-duration-action-large-header::after {
  content: '';
  background: blue;
  transform: skew(-180deg) translateX(-60%);
  z-index: -1;
  height: 2px;
  width: 229px;
  position: absolute;
  top: 30px;
  margin: auto;
}

.chart-duration-action-small-header::after {
  content: '';
  background: blue;
  transform: skew(-180deg) translateX(-80%);
  z-index: -1;
  height: 2px;
  width: 153px;
  position: absolute;
  top: 30px;
  margin: auto;
}

.chart-due-log-meeting-action-table {
  display: grid;
  grid-template-columns: 2fr 2fr 0.8fr 0.8fr 0.5fr 0.5fr 0.5fr 0.5fr;
  row-gap: 0.5rem;
  column-gap: 1rem;
}

.activities-table {
  display: grid;
  grid-template-columns: 2fr 0.5fr 0.8fr 0.5fr 0.5fr;
  row-gap: 0.5rem;
  column-gap: 1rem;
}

.notifications-table {
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 0.3fr;
  row-gap: 0.5rem;
  column-gap: 1rem;
}

.title-border {
  border-bottom: 1px solid rgb(143, 142, 142);
  width: 100%;
  padding-bottom: 0.5rem;
}

.row-border {
  border-top: 2px solid lightgray;
  grid-column: 1 / 9; /* this code makes the row stretch to entire width of the container */
}

.chart-log-meeting-header {
  font-weight: bold;
  /* padding-left: 1rem; */
  font-size: 16px;
  font-size: 16px;
  text-align: left;
  white-space: normal;
  word-break: keep-all;
}

.chart-log-meeting-header-kpi {
  padding-left: 0.5rem;
  font-weight: bold;
  /* padding-left: 1rem; */
  font-size: 14px;
  text-align: left;
}

.chart-log-meeting-description {
  grid-column: 1 / 9;
  font-weight: bold;
}

.chart-log-blocker {
  border-left: 0.2rem solid #d85959 !important;
  padding: 0rem 0rem 1rem 0.6rem;
  text-align: left;
}

.chart-log-decision {
  border-left: 0.2rem solid blue !important;
  padding: 0rem 0rem 1rem 0.6rem;
  text-align: left;
}

.chart-log-update {
  border-left: 0.2rem solid gray !important;
  padding: 0rem 0rem 1rem 0.6rem;
  text-align: left;
}

.chart-log-admin-topic {
  border-left: 0.2rem solid #9933ff !important;
  padding: 0rem 0rem 1rem 0.6rem;
  text-align: left;
}

.topic-btn {
  width: fit-content;
  height: fit-content;
  margin-left: 2rem;
}

.chart-log-topics-table {
  display: grid;
  grid-template-columns: 40px 1.5fr 1.5fr 1fr;
  gap: 1rem;
  position: relative;
}

.chart-topics-objective-header::after {
  content: '';
  background: blue;
  transform: skew(-180deg) translateX(-68%);
  z-index: -1;
  height: 2px;
  width: 280px;
  position: absolute;
  top: 35px;
}

.chart-topics-topics-header::after {
  content: '';
  background: blue;
  transform: skew(-180deg) translateX(-63%);
  z-index: -1;
  height: 2px;
  width: 290px;
  position: absolute;
  top: 35px;
}

.chart-topics-small-header::after {
  content: '';
  background: blue;
  transform: skew(-180deg) translateX(-60%);
  z-index: -1;
  height: 2px;
  width: 230px;
  position: absolute;
  top: 35px;
}
