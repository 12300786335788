/* BRANDING GUIDELINES */

/* PRIMARY COLORS */
/* rgb(9,38, 213)
rgb(237,82,46)
rgb(246,145,60)
rgb(255,255,255) */

//Application Color Palette

//PRIMARY COLORS
$primary-blue-00: #0926d5;
$primary-blue-10: #5970f8;
$primary-blue-20: #90a0fa;
$primary-blue-40: #c8cffd;

$primary-orange-00: #ed522e;
$primary-orange-10: #f49782;
$primary-orange-20: #f8baab;
$primary-orange-40: #fbdcd5;

$primary-yellow-00: #f6913c;
$primary-yellow-10: #fabd8a;
$primary-yellow-20: #fbd3b1;
$primary-yellow-40: #fde9d8;

//SECONDARY COLORS
$secondary-blue-00: #4472c4;
$secondary-blue-10: #7395d3;
$secondary-blue-20: #a2b9e2;
$secondary-blue-40: #d0dcf0;

$secondary-gray-00: #44546a;
$secondary-gray-10: #737f8f;
$secondary-gray-20: #a2aab5;
$secondary-gray-40: #d0d4da;

//NEUTRAL
$neutral-00: #fff;
$neutral-05: #f2f2f2;
$neutral-10: #e6e6e6;
$neutral-20: #cdcdcd;
$neutral-40: #9a9a9a;
$neutral-60: #686868;
$neutral-80: #282828;

//FEEDBACK
$feedback-success-light: #cdd9d0;
$feedback-success-medium: #7a9980;
$feedback-success-dark: #386641;

$feedback-warning-light: #fff6df;
$feedback-warning-medium: #ffe6a8;
$feedback-warning-dark: #ffd97d;

$feedback-information-light: #c8d5de;
$feedback-information-medium: #6c8fa6;
$feedback-information-dark: #22577a;

$feedback-error-light: #e6cac5;
$feedback-error-medium: #bc7063;
$feedback-error-dark: #9b2915;

//BREAKPOINTS (based on Bootstrap Grid Breakpoints)
$bp-xs: 0px;
$bp-sm: 576px;
$bp-md: 768px;
$bp-lg: 992px;
$bp-xl: 1200px;
$bp-xxl: 1400px;

//BACKGROUNDS(Ceci To-do)
// $primary-background:
// $primary-secondary:
// $primary-helper:

body {
  font-family: 'Open Sans', 'Arial', 'Helvetica', 'system-ui', sans-serif;
  color: #425066;
  /* width: 100vw;
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow-x: hidden; */
}

html body .hidden {
  display: none;
}

:root {
  --blue: rgb(9, 38, 213);
  --red: rgb(237, 82, 46);
  --orange: rgb(246, 145, 60);
}

/* scroll bar */

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Lighter track color */
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c1c1c1; /* Lighter gray for subtlety */
  border-radius: 4px; /* Smaller radius for modern look */
  transition: background 0.2s ease; /* Smooth hover effect */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8; /* Slightly darker on hover */
}

/* COMPLEMENTARY COLORS */

/* rgb(5,31,189)
rgb(166,166,166)
rgb(255,255,255) */

html {
  scroll-behavior: smooth;
}

.App {
  position: relative;
}

h2 {
  font-size: 28px;
  margin: 2rem 0 3rem 0;
  max-width: 80%;
  text-align: center;
}

a {
  text-decoration: none;
  color: #425066;
}

.step-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shadow {
  box-shadow: 0 5px 15px rgb(0 0 0 / 20%);
}

.nav-button {
  width: 45px;
  height: 45px;
  cursor: pointer;
}

.next-button {
  position: absolute;
  top: 40vh;
  right: 5vw;
  transform: scaleX(-1);
}

.previous-button {
  position: absolute;
  top: 40vh;
  left: 5vw;
}

.big-btn {
  border: none;
  background-color: rgb(9, 38, 213);
  border-radius: 10px;
  color: rgb(255, 255, 255);
  padding: 0.5rem 1rem;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
}

table {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  width: fit-content;
  height: fit-content;
  padding: 2rem;
}

tr {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.help-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-help-btn {
  width: 20px;
  height: 20px;
  margin-left: 0.5rem;
  cursor: pointer;
}

th,
td {
  width: 10vw;
  padding: 1rem;
  text-align: start;
  margin: 0;
}

th span {
  font-size: 18px;
  font-weight: bold;
}

.form {
  width: 100%;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 1rem;
  text-align: left;
}

.form-field label {
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 16px;
}

.form-field input {
  border: none;
  border-radius: 10px;
  padding: 0.5rem;
  background-color: rgb(242, 242, 242);
  font-size: 14px;
  width: 16rem;
  height: 1.5rem;
}

.form-field textarea {
  border: none;
  border-radius: 10px;
  padding: 0.5rem;
  background-color: rgb(242, 242, 242);
  font-size: 14px;
  width: 16rem;
  height: 4.5rem;
}

// .form button {
//   width: 16vw;
//   padding: 1rem 0.5rem;
//   background-color: rgb(237, 82, 46);
//   margin-top: 1rem;
// }

input,
select {
  border-radius: 2.5px;
  padding: 0.25rem 0.5rem;
  border: 1px solid rgb(238, 238, 238);
  background-color: rgba(238, 238, 238, 0.2);
}

input[type='checkbox'] {
  width: 1.1rem;
  height: 1.1rem;
}

// .wide-cell {
//   width: 15vw;
// }

.add-btn,
.toggle-btn {
  border: none;
  background-color: rgb(9, 38, 213);
  border-radius: 5px;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
  width: 10vw;
  white-space: nowrap;
}

.edit-cap-btn {
  border: none;
  background-color: $primary-orange-00;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
  width: 10vw;
}

.view-edit-activities-btn {
  border: none;
  background-color: $primary-orange-00;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
  width: 13vw;
}

.add-btn img {
  width: 15px;
  height: 15px;
  margin-right: 0.5rem;
}

.modal-tf .bullet-container {
  width: 30vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  margin-bottom: 1rem;
}

.modal-tf .bullet-container b {
  margin-bottom: 1.5rem;
  font-size: 18px;
}

.modal-tf .bullet-container .objective-bullet,
.modal-tf .bullet-container .example-bullet {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-tf .bullet-container .objective-bullet img,
.modal-tf .bullet-container .example-bullet img {
  width: 25px;
  height: 25px;
  margin-right: 0.5rem;
}

.goal-mod-modal .form input {
  width: 23vw;
}

.divider {
  width: 100%;
  border-radius: 1px;
  height: 2px;
  background-color: rgb(238, 238, 238);
}

.divider-blue {
  width: 100%;
  border-radius: 1px;
  height: 2px;
  background-color: rgb(68, 114, 196);
}

.divider-black {
  width: 100%;
  border-radius: 1px;
  height: 1px;
  background-color: black;
}

.modal-title-container {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  gap: 0.5rem;
}

.modal-title-container img {
  margin-right: 0.5rem;
  width: 35px;
  height: 35px;
}

.modal-title {
  font-size: 24px;
}

.validation-error {
  color: rgb(255, 0, 51);
}

.error-bullets {
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.modal-error-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
}

.modal-error-btn .error-btn {
  margin: 0 1rem;
  padding: 0.5rem 0;
  width: 10vw;
  border: none;
  border-radius: 2.5px;
  cursor: pointer;
  font-size: 14px;
  color: white;
}

.modal-error-btn .negative-error-btn {
  background-color: rgb(200, 200, 200);
  color: black;
}

.edit-btn,
.delete-btn {
  width: 12px;
  height: 12px;
  margin-left: 1rem;
  cursor: pointer;
  position: relative;
  bottom: 0.5rem;
}

.edit-btn {
  background-color: rgb(9, 38, 213);
  border-radius: 10px;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.delete-btn {
  background-color: rgb(233, 32, 32);
  border-radius: 10px;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.modal-dual-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
}

.time-input {
  width: 3vw;
}

.time-input-container {
  display: flex;
}

/* ///////////////////////// Wizard intro page ///////////////////////// */

.intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.intro h2 {
  margin: 1rem 0;
}

.intro .intro-title {
  width: 65vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.intro-summary-container {
  width: 65vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2rem;
}

.intro-summary-container .intro-summary-point {
  border-radius: 5px;
  width: 18vw;
  padding: 1.5vw 1vw;
}

.intro-summary-point {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 3rem;
  width: 20%;
}

.intro-summary-point .intro-summary-title {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 18px;
  color: black;
}

.intro-summary-details {
  width: 70%;
  margin-left: 15%;
  text-align: start;
}

/* /////////// Next and Previous buttons on wizards ///////////   */

.dual-button-container .dual-btn {
  padding: 0.75rem 0;
  width: 7rem;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.dual-button-container button {
  background-color: rgb(237, 82, 46);
  height: 2rem;
}

.dual-button-container button:hover {
  background-color: rgb(246, 145, 60);
}

.dual-btn .nav-arrow {
  width: 1rem;
  height: 1rem;
}

.dual-button-container .disabled {
  opacity: 0.5;
  cursor: auto;
  pointer-events: none;
}

.dual-button-container .disabled:hover {
  background-color: rgb(237, 82, 46);
}

.dual-button-container .dual-next-btn span {
  position: relative;
  right: 0.25rem;
}

.start-button {
  height: 3rem;
  background-color: rgb(237, 82, 46);
  // font-size: 1.8rem;
  padding: 1rem 2rem;
  width: 15vw;
  border-radius: 5rem;
  font-size: 16px;
}

/* ////////// */
.disabled-link {
  pointer-events: none;
}

/* ////////// add-employee-modal ////////// */

.add-employee-modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  z-index: 3;
}

.add-employee-modal .modal-tf {
  position: relative;
  width: 50vw;
  background-color: #fff;
  padding: 2vw;
  border-radius: 5px;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.3);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 90vh;
  overflow-y: auto;
}

.add-employee-modal .modal-title-container img {
  width: 45px;
  height: 45px;
  margin-right: 1rem;
}

.add-employee-modal label {
  margin-bottom: 0.75rem;
}

.add-employee-modal .form-field {
  margin-bottom: 2rem;
}

////////////// Wizard Step definition

.wizard-container {
  padding-top: 5rem;
}

.wizard-step {
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  word-break: break-word;
}

.wizard-step__title {
  min-width: 40vw;
  max-width: 80%;
  margin: 2rem 0 2rem 0;
}

.wizard-step__overview {
  max-width: 70%;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: $bp-md) {
    max-width: 90%;
  }
}

.wizard-step__overview img {
  width: 35px;
  height: 35px;
  margin-right: 1rem;
}

.wizard-step__overview__paragraph {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
}

.wizard-step__main-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 4rem 2rem 4rem;
  border-radius: 5px;
  min-width: 85%;
  max-width: 85%;
  row-gap: 1rem;
  box-shadow: 0 5px 15px rgb(0 0 0 / 20%);
}

.wizard-step__single-answer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 4rem 2rem 4rem;
  row-gap: 1rem;
  width: 100%;
}

.wizard-step__begin-button {
}

////////////team step specifics

.wizard-step .objective-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.wizard-step .statement-wrapper {
  width: 100%;
  display: flex;
}

.wizard-step .statement-container {
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgb(238, 238, 238);
  height: 50px;
  font-size: 18px;
  display: flex;
  align-items: flex-start;
  padding: 0.5rem;
}

.wizard-step .warning {
  width: 22px;
  height: 22px;
  top: 12.5px;
  left: -2rem;
}

.wizard-step .statement--invalid {
  border: rgb(255, 0, 0) 1px solid;
}

/////////////////////temp

.wizard-step select {
  width: 100%;
  height: 2rem;
  border-radius: 5%;
}

.wizard-step ul {
  margin-bottom: 2rem;
  list-style: none;
}

.wizard-step .edit-btn,
.wizard-step .delete-btn {
  width: 12px;
  height: 12px;
  margin-left: 1rem;
  cursor: pointer;
  position: unset;
  bottom: 0.5rem;
}

.wizard-step .add-btn {
  margin-top: 1rem;
}

.wizard-step .no-objectives-text {
  font-size: 18px;
  margin-bottom: 2rem;
}

.left-aligned-wizard-step {
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  word-break: break-word;
}

.left-aligned-wizard-step__single-answer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 4rem 2rem 4rem;
  row-gap: 1rem;
  width: 100%;
}

.disabled-button {
  color: #ae2323;
}

//remove arrows/spinners from input type number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.icon-button {
  width: 2rem;
  height: 2rem;
  padding: 0.3rem 0px;
  font-size: 14px;
  border: none;
  color: rgb(255, 255, 255);
  // border-radius: 2.5px;
  cursor: pointer;
  bottom: 4vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.comment-icon-button {
  background-color: var(--blue);
  width: 1.7rem;
  height: 1.7rem;
}

.group-icon-button {
  background-color: var(--blue);
}

.activity-board-icon-button-disabled {
  background-color: rgb(153, 194, 255);
}

.activity-board-icon-button {
  background-color: var(--blue);
}

.actions-icon-button {
  background-color: var(--red);
  border-radius: 8px;
}

.icon-size-xl {
  font-size: 24px;
}

.icon-size-l {
  font-size: 22px;
}

.icon-size {
  font-size: 20px;
}

.icon-size-sm {
  font-size: 15px;
}

.comment-icon-size {
  font-size: 15px;
}

.icon-button-no-border {
  width: 2rem;
  height: 2rem;
  padding: 0.3rem 0px;
  font-size: 14px;
  border: none;
  border-radius: 2.5px;
  cursor: pointer;
  bottom: 4vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.spin-180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.option-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  border-radius: 4px;
  color: white;
  padding: 0.3rem 1rem 0.3rem 1rem;
}

.sleek-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  border-radius: 10px;
  color: white;
  padding: 0.3rem 1rem 0.3rem 1rem;
  height: 2.2rem;
  gap: 0.5rem;
  font-size: 14px;
}

.sleek-full-blue {
  background-color: #4472c4;
  color: #fff;
  border: 1px solid #4472c4;
}

.sleek-full-orange {
  background-color: rgb(237, 82, 46);
  color: #fff;
  border: 1px solid rgb(237, 82, 46);
}

.sleek-full-red {
  background-color: #eb001b;
  color: #fff;
  border: 1px solid #eb001b;
}

.sleek-full-green {
  background-color: #1db81a;
  color: #fff;
  border: 1px solid #1db81a;
}

.sleek-full-grey {
  background-color: #777777;
  color: #fff;
  border: 1px solid #777777;
}

.sleek-blue {
  color: #4472c4;
  border: 1px solid #4472c4;
}

.sleek-red {
  color: #eb001b;
  border: 1px solid #eb001b;
}

.sleek-green {
  color: #1db81a;
  border: 1px solid #1db81a;
}

.play-circle {
  color: #4472c4;
}

.pause-circle {
  color: #eb001b;
}

.no-border-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  border-radius: 6px;
  padding: 0.2rem;
}

.no-border-button-gray {
  color: rgb(53, 65, 65);
}

.no-border-button-blue {
  color: #0926d5;
}

.fitted-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  border-radius: 6px;
  padding: 0.2rem;
}

.highlight-border-accept {
  color: black;
  background-color: #90a0fa;
  border: 3px solid blue;
}

.highlight-border-reject {
  color: black;
  background-color: #f49782;
  border: 3px solid red;
}

.accept-button {
  color: black;
  background-color: #c8cffd;
}

.reject-button {
  color: black;
  background-color: #fbdcd5;
}

.modal-save-button {
  font-family: 'Open Sans', 'Arial', 'Helvetica', 'system-ui', sans-serif;
  font-size: unset;
  background-color: white;
}

.blue {
  color: #0926d5;
  border: 2px solid #e4e7fd;
}

.blue-no-border {
  color: #0926d5;
}

.red {
  color: red;
  border: 2px solid #ffeeee;
}

.red-no-border {
  color: red;
}

.orange-no-border {
  color: orange;
}

.gray {
  color: rgb(53, 65, 65);
  border: 2px solid #e4e7fd;
}

.red-notification {
  color: rgb(207, 96, 96);
  border: 2px solid #efcaca;
}

.yellow-notification {
  color: rgb(227, 217, 23);
  border: 2px solid #f6f2b2;
}

.green-notification {
  color: rgb(157, 200, 162);
  border: 2px solid #deede0;
}

.rag-letter-box {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 6px;
  padding: 0.2rem;
  color: white;
}

.rag-letter-box-sm {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  width: 1rem;
  height: 1rem;
  border-radius: 6px;
  padding: 0.2rem;
  color: white;
}

.red-rag-box {
  background-color: red;
}

.amber-rag-box {
  background-color: gold;
}

.green-rag-box {
  background-color: green;
}

.gray-rag-box {
  background-color: lightgray;
}

.checklist-done {
  align-self: center;
  font-size: 1.25rem;
  cursor: pointer;
  padding: 0px;
  margin-left: 0.5rem;
  border-radius: 6px;
}

.checklist-done-green {
  color: white;
  background-color: rgb(119, 191, 114);
  border-radius: 6px;
  border: rgb(119, 191, 114) 1px solid;
  padding: 1px;
}

.checklist-done-grey {
  color: grey;
  background-color: white;
  border-radius: 6px;
  border: grey 1px solid;
  padding: 1px;
}

.checklist {
  color: grey;
  font-size: 1.25rem;
  cursor: pointer;
  padding: 0px;
  margin-left: 0.5rem;
  background-color: white;
  border-radius: 6px;
}

.checklist-green {
  color: white;
  font-size: 1.25rem;
  cursor: pointer;
  padding: 0px;
  margin-left: 0.5rem;
  background-color: rgb(119, 191, 114);
  border-radius: 6px;
}

.green-check {
  color: white;
  font-size: 1.25rem;
  cursor: pointer;
  padding: 0px;
  margin-left: 0.5rem;
  background-color: rgb(119, 191, 114);
  border-radius: 6px;
}

.grey-check {
  color: grey;
  font-size: 1.25rem;
  cursor: pointer;
  padding: 0px;
  margin-left: 0.5rem;
  background-color: white;
  border-radius: 6px;
}

.red-icon {
  color: #990000;
}

.invisible-check {
  opacity: 0;
}

.gray {
  color: gray;
  border: 2px solid #e4e7fd;
}

.inactive-blue {
  color: #aab0d1;
  border: 2px solid #e4e7fd;
}

.invalidate-click {
  pointer-events: none;
}

.bolder {
  font-weight: bolder;
}

.extra-lateral-spacing {
  margin: 0px 0.5rem;
}

.material-symbols-outlined {
  font-variation-settings:
    'FILL' 1,
    'wght' 500,
    'GRAD' 200,
    'opsz' 40;
}

.spacing-bottom {
  margin-bottom: 2rem;
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  // previous 120px
  width: 10rem;
  background-color: #555;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -90px;
  opacity: 0;
  transition: opacity 0.3s;
  padding: 0.5rem 0.3rem 0.5rem 1rem;
  white-space: normal;
  font-weight: normal;
  font-size: '16px';
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip .tooltiptext.bgu_kanban_desc::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 35%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip .tooltiptext.meeting_notes::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 47%;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  z-index: 1002;
}

.tooltip .tooltiptextname {
  visibility: hidden;
  // previous 120px
  width: 10rem;
  background-color: #555;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -90px;
  opacity: 0;
  transition: opacity 0.3s;
  padding: 0.5rem 0.3rem 0.5rem 1rem;
  white-space: normal;
  font-weight: normal;
  font-size: '16px';
}

.tooltip .tooltiptextname::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 20%;
  margin-left: -20px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptextname {
  visibility: visible;
  opacity: 1;
  z-index: 1002;
}

.parents-frame {
  border-radius: 3px;
  padding: 0.2rem;
  color: #74777c;
  max-width: 20rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.line_break_div {
  white-space: pre-wrap;
  word-break: break-word;
}

.task-inner-container {
  .task-title {
    margin: 0 0 30px 0;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    word-break: break-all;
  }

  .card_header_taskbox {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row-reverse;
  }
}

.task-action-conatiner {
  display: flex;
  // align-items: center;
  justify-content: flex-end;

  span {
    font-size: 14px;
    color: rgb(9, 38, 213);
    line-height: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    cursor: pointer;
    // &:first-child {
    //   padding-right: 8px;
    //   border-right: 1px solid black;
    //   margin-right: 8px;
    // }
  }
}

.circle-small {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 12px;
}

.circle-small-medium {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  font-size: 12px;
}

.circle-medium {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  font-size: 14px;
}

.user-initials-circle {
  display: flex;
  border: 2px solid white; //#eceff3; gray color used before for reference
  color: white;
  font-weight: 400;
  line-height: 24px;
  justify-content: center;
  align-items: center;
}

.tast-create-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 0px 0px 4px;
  border-bottom: 1px solid #f6f6f6;

  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #7e7e7e;
  }
}

.activity_filter_box {
  .label_custom {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #111111;
  }
}

.btn-icon,
.kanban_header_container .btn-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11px;
  background: rgb(9 38 213 / 5%);
  border-radius: 8px;
  color: #4025d4;
  cursor: pointer;
  border: none;
  height: 44px;
  width: 44px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
}

.accordion_box .szh-accordion__item-heading button {
  background-color: transparent;
  border: none;
  width: 100%;

  div {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    /* identical to box height, or 100% */

    color: #434343;
  }
}

.mendatory_field_Custom {
  display: grid;
  grid-template-columns: repeat(3, 33%);
  gap: 1rem;
  background: #f9f9f9;
  border-radius: 8px;
  padding: 16px 52px;

  .mendatory_field_txt {
    white-space: nowrap;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    border-left: 1px solid;
    padding-left: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .field_txt_red {
    border-color: #e82f42;
  }

  .field_txt_grey {
    border-color: #7e7e7e;
  }

  .field_txt_blue {
    border-color: #4025d4;
  }
}

// .box_header div{
//   font-weight: 400;
// font-size: 16px;
// line-height: 24px;
// color: #000000;

// }
.box_header_wrapper {
  .ijfjAw {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    // padding: 0px;
  }

  .title_count {
    background: red;
    border-radius: 50px;
    margin-left: 8px;
    padding: 1px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

.box_header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-left: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;

  .title_symbol {
    position: absolute;
    top: 7px;
    left: -13px;
    width: 8px;
    height: 8px;
    background-color: red;
    display: block;
    border-radius: 100%;
  }

  // .title_count
}

.NOT_STARTED_box {
  .title_count {
    color: #6e6a67;
    background: #d5d5d5;
  }

  .title_symbol {
    background: #6e6a67;
  }
}

.ON_TRACK_box {
  .title_count {
    color: #2260ff;
    background: #e4ecff;
  }

  .title_symbol {
    background: #2260ff;
  }
}

.FOR_REVIEW_box {
  .title_count {
    color: #ff2020;
    background: #ffe4e4;
  }

  .title_symbol {
    background: #ff2020;
  }
}

.COMPLETED_box {
  .title_count {
    color: #1ea343;
    background: #b4ecc3;
  }

  .title_symbol {
    background: #1ea343;
  }
}

.DEPRIORITIZED_box {
  .title_count {
    color: #ffde30;
    background: #fffbe9;
  }

  .title_symbol {
    background: #ffde30;
  }
}

.BACKLOG_box {
  .title_count {
    color: white;
    background: gray;
  }

  .title_symbol {
    background: lightblue;
  }
}

.task-container-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  width: 100%;

  .assigned_label {
    font-weight: 400;
    font-size: 10px;
    line-height: 6px;
    color: #7e7e7e;
  }
}

.assigned_box {
  display: flex;
  // flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 4px;

  .assigned_user_box {
    position: relative;
    display: flex;
    height: 100%;

    .assigned_user {
      position: absolute;

      width: 28px;
      height: 28px;

      border: 1px solid #ffffff;
      font-weight: 400;
      font-size: 9px;
      line-height: 6px;
      border-radius: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .first_user {
      top: 0px;
      left: 0px;
      background: #f1f3ff;
      color: #6628ff;
    }

    .second_user {
      top: 0px;
      left: 18px;
      background: #fffdf1;
      color: #ffde30;
    }

    .third_user {
      top: 0px;
      left: 36px;
      background: #f2fff9;
      color: #039855;
    }

    .see_more {
      top: 0px;
      left: 54px;
      background: #f1f3ff;
      color: #6628ff;
    }
  }
}

.task_btn_box {
  display: flex;
  gap: 10px;
}

.szh-accordion__item {
  position: relative;
}

.kanban_header_container {
  position: absolute;
  top: 0px;
  right: 0px;
}

.custom-emoji-icon-toast {
  font-size: 40px;
  /* Adjust the font size as needed */
  background: none;
  /* Remove background */
  border: none;
  /* Remove border */
  padding: 0;
  /* Remove padding */
  margin: 0;
  /* Remove margin */
  z-index: 10;
}

.modal-gray-box {
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 1.5rem;
}

.modal-inner-content {
  margin: 1rem;
}

.modal-inner-title {
  font-weight: bold;
  font-size: 20px;
  color: #4472c4;
  text-align: left;
  font-family: 'Open Sans', Arial, Helvetica, 'system-ui', sans-serif;
  margin: 1rem 0rem;
}

.modal-outer-container {
  .modal-container {
    text-align: left;
    .modal-container-padding {
      width: 90%;
      padding: 0;
    }
  }
}

.page_title {
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  margin: 0rem 0rem 1rem 0.5rem;
}

@media print {
  @page {
    size: 297mm 210mm;
    /* landscape */
    /* you can also specify margins here: */
    margin: 25mm;
    margin-right: 45mm;
    /* for compatibility with both A4 and Letter */
  }
}

.swal-chrome-browser-check {
  font-size: 20px;
}

.swal-title-xs {
  font-size: 1.2rem;
}

.swal2-title-left {
  text-align: left !important;
}
