.decision-log-meeting-table {
  display: grid;
  /* grid-template-columns: 0.05fr 2fr; Bring back if we want indentation */
  grid-gap: 1rem;
}

.decision-log-meeting-header {
  font-weight: bold;
}

.decision-log-meeting-description {
  grid-column: 1 / 4;
  font-weight: bold;
}

.decision-log-blocker {
  border-left: 0.2rem solid #d85959;
  padding: 0rem 1rem 1rem 0.5rem;

  display: table;
  table-layout: fixed;
  word-break: break-word;
}

.decision-log-decision {
  border-left: 0.2rem solid blue;
  padding: 0rem 1rem 1rem 0.5rem;

  display: table;
  table-layout: fixed;
  word-break: break-word;
}

.decision-log-update {
  border-left: 0.2rem solid gray;
  padding: 0rem 1rem 1rem 0.5rem;

  display: table;
  table-layout: fixed;
  word-break: break-word;
}

.decision-log-admin {
  border-left: 0.2rem solid #9933ff;
  padding: 0rem 1rem 1rem 0.5rem;

  display: table;
  table-layout: fixed;
  word-break: break-word;
}

.decision-log-comment {
  margin-bottom: 0.5rem;
  width: 48rem;
  white-space: pre-wrap;
  align-items: center;
  padding-bottom: 1rem;
  /* word-break: break-word; */
}

.decision-log-milestone {
  margin-bottom: 0.5rem;
  width: 48rem;
  white-space: pre-wrap;
  align-items: center;
  padding-bottom: 1rem;
  /* word-break: break-word; */
}

.decision-log-inner-grid-area-view {
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 2%;
}

.decision-log-actions-row {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  padding-left: 1rem;
}
