.meeting-action-comments-view {
  display: grid;
  grid-template-columns: 0.3fr 0.7fr 6fr 0.6fr;
  grid-gap: 2%;
  padding: 0.5rem;
}

.meeting-action-comments-view-finalized {
  display: grid;
  grid-template-columns: 0.7fr 6fr 0.6fr;
  grid-gap: 2%;
  padding: 0.5rem;
}

.shadow-topic {
  /* box-shadow: 0 5px 15px rgb(0 0 0 / 20%); */
  border: 1px solid rgb(0 0 0 / 20%);
  border-radius: 10px;
}

.underline-meeting::after {
  content: '';
  background: rgb(68, 114, 196);
  transform: skew(-180deg);
  /* z-index: -1; */
  height: 2px;
  width: 85%;
  position: absolute;
  margin: auto;
}

.underline-meeting-light::after {
  content: '';
  background: lightgray;
  transform: skew(-180deg);
  /* z-index: -1; */
  height: 0.5px;
  width: 85%;
  position: absolute;
  margin: auto;
}
