.kpi-review-table {
  display: flex;
  flex-direction: column;
  gap: 10px;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
  text-align: left;
  width: 100%;

  /* box-shadow: 0 5px 15px rgb(0 0 0 / 20%); */
  border: 1px solid rgb(0 0 0 / 20%);
  border-radius: 10px;
  padding: 8px;
  word-wrap: break-word;
}

.kpi-review-header-row {
  display: grid;
  grid-template-columns: 1.5fr 0.5fr 0.7fr 2fr 1fr;
  background-color: #f0f0f0;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}

.kpi-review-row {
  display: grid;
  grid-template-columns: 1.5fr 0.5fr 0.7fr 2fr 1fr;
  column-gap: 0.5rem;
  border-bottom: 1px solid #ddd;
}

.kpi-review-cell {
  margin-top: auto;
  margin-bottom: auto;
}

.kpi-review-header {
  font-weight: bold;
  /* padding-left: 1rem; */
  font-size: 14px;
  text-align: left;
  white-space: normal;
  word-break: break-word;
}

.status-green {
  color: #3bc13b;
  font-weight: bold;
}

.status-red {
  color: red;
  font-weight: bold;
}

.status-grey {
  color: grey;
  font-weight: bold;
}

.status-gold {
  color: gold;
  font-weight: bold;
}

.kpi-comment-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.kpi-review-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.kpi-update-button:hover {
  background-color: #4670ba;
}

/* Add these styles for the dropdown */
.status-select {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-weight: bold;
  cursor: pointer;
  width: 80%;
}

.status-select.status-green {
  color: #3bc13b;
}

.status-select.status-red {
  color: red;
}

.status-select.status-yellow {
  color: yellow;
}

.status-select.status-grey {
  color: grey;
}

.value-input {
  width: 80%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Add these styles for validation errors */
.validation-error-message {
  background-color: #ffebee;
  color: #d32f2f;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  border-left: 4px solid #d32f2f;
  font-weight: bold;
}

.validation-error-row {
  position: relative;
}

.validation-error-row::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  pointer-events: none;
}
