.zisi-chatbot {
  /* width: 400px; */
  /* height: 500px; */
  height: 0px;
  width: 20vw;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition:
    height 0.3s,
    width 0.3s;

  /* border-left: 1px solid rgb(244, 127, 75);
  border-right: 1px solid rgb(244, 127, 75); */

  border-left: 1px solid #fbe7d9;
  border-right: 1px solid #fbe7d9;
}

.zisi-chatbot-header {
  padding: 10px;
  text-align: center;
  color: #000;
  max-height: 40px;
  font-size: 16px;
  font-weight: bold;
  /* background-color: #fbe7d9; */
  background-color: #fbe7d9;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

  /* border-top: 1px solid rgb(244, 127, 75);
  border-left: 1px solid rgb(244, 127, 75);
  border-right: 1px solid rgb(244, 127, 75);
   */
  border-top: 1px solid #fbe7d9;
  border-left: 1px solid #fbe7d9;
  border-right: 1px solid #fbe7d9;

  cursor: pointer;
}

.zisi-chatbot-container {
  position: fixed;
  bottom: 0;
  right: 0;
  padding-bottom: 0px;
  padding-right: 10px;

  z-index: 300;
}

.zisi-message-bubble {
  max-width: 75%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  word-break: break-word;
}

.zisi-user-bubble {
  background-color: rgba(248, 203, 184, 0.15);
  margin-left: auto;
  margin-right: 5px;
}

.zisi-bot-bubble {
  background-color: #f2f2f2;
  text-align: left;
  min-width: 50px;
  margin-left: 10px;
}

.zisi-bot-encapsulation-container {
  display: flex;
  align-items: flex-start;
}

.zisi-bot-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-left: 5px;
}

.zisi-message-container {
  /* width: 400px; */
  height: 80vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 1.5vh 0px;
}

.zisi-expanded-chatbot {
  width: 30vw;
  height: 60vh;
  /* height: 500px; */
  /* width: 430px; */
}
.zisi-minimized-chatbot {
  height: 500px;
  max-width: 430px;
}

.zisi-hiddenChatbot {
  display: none;
}

.zisi-shownChatbot {
  display: block;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes revealDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Add these new styles to your existing CSS file */

.zisi-bot-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #4472c4;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  flex-shrink: 0;
  margin-right: 8px;
}

.zisi-bot-avatar-initial {
  font-size: 16px;
  font-weight: 500;
}

.zisi-bot-message-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  margin-left: 12px;
}

.zisi-bot-content {
  flex: 1;
}

.zisi-bot-name-container {
  display: flex;
  align-items: baseline;
  margin-bottom: 4px;
}

.zisi-bot-name {
  font-weight: 600;
  color: #333;
}

.zisi-bot-label {
  margin-left: 8px;
  font-size: 0.875rem;
  color: #666;
}
