.excel-upload-button {
  display: inline-block;
  position: relative;

  .upload-loading-spinner {
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
  }

  .disabled {
    opacity: 0.7;
  }

  .tooltip {
    position: relative;
    display: inline-block;

    .tooltiptext {
      visibility: hidden;
      width: 200px;
      background-color: #555;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px;
      position: absolute;
      z-index: 1;
      bottom: 125%;
      left: 50%;
      margin-left: -100px;
      opacity: 0;
      transition: opacity 0.3s;
      font-size: 14px;
    }

    &:hover .tooltiptext {
      visibility: visible;
      opacity: 1;
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
