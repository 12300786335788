.phoenix {
  .loading-spinner {
    display: inline-block;
    width: 3rem;
    height: 3rem;
    border: 0.3rem solid rgba(124, 124, 124, 0.411);
    border-radius: 50%;
    border-top-color: rgb(9, 38, 213);
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
    margin: auto;
  }

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  .loading-container {
    display: flex;
    flex-flow: column;
    height: 75vh;
  }

  .loading-container-with-error {
    display: flex;
    flex-flow: column;
  }
}
