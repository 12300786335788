.modal-container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-container {
  width: 30rem;
  max-width: 32rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: flex-start;
  margin-top: 1rem;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 20rem;
}

.label {
  font-weight: 600;
  color: #333;
}

.input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
}

.input.has-error {
  border-color: #dc3545;
}

.input:focus {
  outline: none;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.input.has-error:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.error-message {
  color: #dc3545;
  font-size: 0.875rem;
}

.button {
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  min-width: 100px;
}

.button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.button.cancel {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  color: #495057;
}

.button.cancel:hover:not(:disabled) {
  background-color: #e9ecef;
}

.button.save {
  background-color: #007bff;
  border: 1px solid #007bff;
  color: white;
}

.button.save:hover:not(:disabled) {
  background-color: #0056b3;
}

.edit-decision-log-modal-title {
  font-weight: bold;
  /* padding-left: 1rem; */
  font-size: 25px;
  text-align: left;
  white-space: normal;
  word-break: break-word;
  color: rgb(94, 134, 204);
}
