.floatingedges {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 80vh;
  width: 100%;
  overflow: hidden;
}

.floatingedgesReadOnly {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.floatingedges .react-flow__handle {
  opacity: 0;
}

// Custom scrollbar styling
.nodrag.text-updater-node {
  div[style*='overflow-y: auto'] {
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}
