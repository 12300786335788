.shadow-meeting-report {
  /* box-shadow: 0 5px 15px rgb(0 0 0 / 20%); */
  border: 1px solid rgb(0 0 0 / 20%);
  border-radius: 10px;
  padding-bottom: 2rem;
}

.meeting_report-inner-grid {
  display: grid;
  grid-template-columns: 5fr 3fr 3fr 3fr 4fr 4fr 4fr 1fr;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  column-gap: 1rem;
}

.meeting_report-header {
  font-weight: bold;
  /* padding-left: 1rem; */
  font-size: 16px;
  text-align: left;
  white-space: normal;
  word-break: break-word;
  height: 2.5rem;
  padding-top: 0.7rem;
}
