.email-composer {
  margin: 0 auto;
  background: #ffffff;
  border-radius: 8px;

  .email-composer__header {
    padding: 1.5rem;
    border-bottom: 1px solid #e5e7eb;
  }

  .email-composer__title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
  }

  .email-composer__icon {
    width: 1.25rem;
    height: 1.25rem;
  }

  .email-composer__content {
    padding: 1.5rem;
  }

  .email-composer__footer {
    padding: 1rem 1.5rem;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .form-label {
    display: flex;
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    text-align: left;
    justify-content: flex-start;
    width: 100%;
  }

  .form-input {
    width: 96.5%;
    padding: 0.5rem 0.75rem;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    font-size: 0.875rem;
    font-family: 'Roboto', sans-serif;
    background-color: #ffffff;
    //height: 38px;

    &::placeholder {
      color: #6b7280;
      font-size: 0.875rem;
      font-family: 'Roboto', sans-serif;
    }

    &:focus {
      outline: none;
      border: 2px solid #2684ff;
    }
  }

  .form-textarea {
    min-height: 8rem;
    resize: none;
  }

  .form-group-checkbox {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1rem;
  }

  .form-checkbox {
    width: 1rem;
    height: 1rem;
  }

  .form-checkbox-label {
    font-size: 0.875rem;
  }

  .submit-button {
    background-color: #2563eb;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    border: none;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .submit-button:hover {
    background-color: #1d4ed8;
  }

  .submit-button:focus {
    outline: 2px solid #2563eb;
    outline-offset: 2px;
  }

  .form-input:focus,
  .form-checkbox:focus {
    outline: none;
    border: 2px solid #2563eb;
  }

  .email-composer__form {
    width: 100%;
  }

  .validation-error {
    color: red;
    text-align: left;
  }

  .email-composer__form {
    .css-13cymwt-control {
      border: 1px solid #d1d5db;
      border-radius: 4px;
      font-family: 'Roboto', sans-serif;
      font-size: 0.875rem;
      background-color: #ffffff;
      min-height: 38px;

      &:hover {
        border-color: #d1d5db;
      }
    }

    .css-t3ipsp-control {
      border-color: #2563eb;
      box-shadow: none;
      outline: 2px solid #2563eb;
      outline-offset: 2px;

      &:hover {
        border-color: #2563eb;
      }
    }

    .css-1nmdiq5-menu {
      font-family: 'Roboto', sans-serif;
      font-size: 0.875rem;
    }

    .css-1dimb5e-singleValue {
      font-family: 'Roboto', sans-serif;
    }

    .css-qbdosj-Input input {
      font-family: 'Roboto', sans-serif;
    }
  }

  textarea.form-input {
    height: auto;
  }

  /* New Distribution List Styles */
  .recipients-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .distribution-list-controls {
    position: relative;
  }

  .dl-button {
    background-color: #f3f4f6;
    border: 1px solid #d1d5db;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: #e5e7eb;
    }
  }

  .dl-dropdown {
    position: absolute;
    right: 0;
    top: 100%;
    margin-top: 0.25rem;
    width: 16rem;
    background-color: white;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    box-shadow:
      0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    z-index: 50;

    &-header {
      padding: 0.75rem 1rem;
      font-weight: 500;
      border-bottom: 1px solid #e5e7eb;
    }

    &-content {
      max-height: 15rem;
      overflow-y: auto;
    }
  }

  .dl-item {
    padding: 0.625rem 1rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background-color: #f9fafb;
    }

    .dl-member-count {
      font-size: 0.75rem;
      color: #6b7280;
    }
  }

  .dl-no-lists {
    padding: 1rem;
    color: #6b7280;
    text-align: center;
    font-style: italic;
  }

  .dl-create-new {
    padding: 0.625rem 1rem;
    color: #2563eb;
    border-top: 1px solid #e5e7eb;
    cursor: pointer;
    font-weight: 500;

    &:hover {
      background-color: #f9fafb;
    }
  }
}

/* Distribution List Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.dl-modal {
  width: 100%;
  max-width: 30rem;
  background-color: white;
  border-radius: 0.5rem;
  overflow: hidden;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #e5e7eb;

    h3 {
      margin: 0;
      font-weight: 600;
      font-size: 1.125rem;
    }

    .close-button {
      background: none;
      border: none;
      font-size: 1.5rem;
      cursor: pointer;
      color: #6b7280;

      &:hover {
        color: #111827;
      }
    }
  }

  &-content {
    padding: 1.5rem;
    text-align: left;

    .dl-form-group {
      text-align: left;
    }

    .dl-form-label {
      text-align: left;
    }
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    padding: 1rem 1.5rem;
    border-top: 1px solid #e5e7eb;
    gap: 0.75rem;
  }
}

.dl-form-group {
  margin-bottom: 2rem;
  width: 100%;
  text-align: left;

  &:last-child {
    margin-bottom: 0;
  }
}

.dl-form-label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  text-align: left;
  width: 100%;

  span {
    color: #dc2626;
  }
}

.dl-form-input {
  width: 96.5%;
  padding: 0.5rem 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  font-size: 0.875rem;
  font-family: 'Roboto', sans-serif;
  background-color: #ffffff;

  &::placeholder {
    color: #6b7280;
    font-size: 0.875rem;
    font-family: 'Roboto', sans-serif;
  }

  &:focus {
    outline: none;
    border: 2px solid #2563eb;
  }
}

.dl-validation-error {
  color: red;
  text-align: left;
}

.dl-members-container {
  max-height: 12rem;
  overflow-y: auto;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  padding: 0.5rem;
  text-align: left;
}

.dl-member-item {
  padding: 0.375rem 0.5rem;
  border-radius: 0.25rem;
  background-color: #f3f4f6;
  margin-bottom: 0.375rem;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  span {
    text-align: left;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.dl-no-members {
  padding: 1rem;
  color: #6b7280;
  text-align: left;
  font-style: italic;
}

.cancel-button {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-weight: 500;
  cursor: pointer;
  background-color: white;
  border: 1px solid #d1d5db;

  &:hover {
    background-color: #f9fafb;
  }
}

.save-button {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-weight: 500;
  cursor: pointer;
  background-color: #2563eb;
  border: 1px solid #2563eb;
  color: white;

  &:hover {
    background-color: #1d4ed8;
  }
}

.recipients-container {
  display: flex;
  gap: 10px;
  align-items: flex-start;

  .recipients-select-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .current-dl {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 0.875rem;
      color: #4b5563;

      .current-dl-label {
        color: #6b7280;
      }

      .current-dl-name {
        font-weight: 500;
        color: #374151;
      }

      .edit-dl-button {
        padding: 2px 8px;
        background: none;
        border: none;
        color: #2563eb;
        font-size: 0.875rem;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .distribution-list-controls {
    flex-shrink: 0;

    .dl-button {
      height: 38px;
      padding: 0 12px;
      border: 1px solid #d1d5db;
      border-radius: 4px;
      background-color: white;
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: #f9fafb;
      }
    }
  }
}
