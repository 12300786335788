.input {
  position: relative;
  width: 100%;
}

.input > form {
  display: flex;
  align-items: center;
  width: 100%;
}

.input > form > input[type='text'] {
  flex: 1; /* Ensures input takes available space */
  font-size: 16px;
  border: 0;
  border-radius: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid #eee;
  box-shadow: none;
  box-sizing: border-box;
  opacity: 1;
  outline: none;
  padding: 16px 10px; /* Adjusted padding */
  width: 100%;
  appearance: none;
  height: 7vh;
}

.input > form > .send-button {
  height: 7vh;
  color: #0926d5;
  border: 2px solid #e4e7fd;
  background-color: transparent;
  border-bottom-right-radius: 10px;
  box-shadow: none;
  cursor: pointer;
  fill: #4a4a4a;
  opacity: 1;
  outline: none;
  padding: 14px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; /* Remove absolute positioning */
  border-top-left-radius: 0;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 10px;
}

.see-more-link {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

.see-more-link:hover {
  color: darkblue;
}

.chatbot-team-performance-table {
  display: flex;
  flex-direction: column;
  gap: 10px;
  row-gap: 0.3rem;
  column-gap: 0.5rem;
  text-align: left;
  width: 100%;

  /* box-shadow: 0 5px 15px rgb(0 0 0 / 20%); */
  border: 1px solid rgb(0 0 0 / 20%);
  border-radius: 10px;
  padding: 8px;
  word-wrap: break-word;
}

.chatbot-team-performance-header {
  font-weight: bold;
  /* padding-left: 1rem; */
  font-size: 14px;
  text-align: left;
  white-space: normal;
  word-break: break-word;
}

.chatbot-team-performance-item {
  margin-top: auto;
  margin-bottom: auto;
}

.chatbot-team-performance-row {
  display: grid;
  grid-template-columns: 0.7fr 0.6fr 0.6fr 0.6fr 0.7fr 0.6fr;
}

.chatbot-team-performance-row-cxo {
  display: grid;
  grid-template-columns: 0.7fr 0.6fr 0.6fr 0.6fr 0.7fr 0.6fr 0.6fr;
}
