@keyframes infinite-rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.right-panel.collapsed {
  width: 10%;
  /* background-color: #d3d3d3; */
  transition: all 0.5s;
  /* height: 45rem; */
}

.right-panel.expanded {
  width: 30%;
  margin-right: '2rem';
}

.brainstorm-sessions-table {
  display: flex;
  flex-direction: column;
  gap: 10px;
  row-gap: 0.3rem;
  column-gap: 0.5rem;
  text-align: left;
  width: 95%;

  /* box-shadow: 0 5px 15px rgb(0 0 0 / 20%); */
  border: 1px solid rgb(0 0 0 / 20%);
  border-radius: 10px;
  padding: 8px;
  word-wrap: break-word;
}

.brainstorm-sessions-header {
  font-weight: bold;
  /* padding-left: 1rem; */
  font-size: 16px;
  text-align: left;
  white-space: normal;
  word-break: break-word;
  gap: 0.2rem;
}

.chatbot-team-performance-item {
  margin-top: auto;
  margin-bottom: auto;
}

.brainstorm-sessions-row-hide-tag {
  display: grid;
  grid-template-columns: 0.7fr 0.4fr 0.4fr;
  gap: 0.2rem;
}

.brainstorm-sessions-row {
  display: grid;
  grid-template-columns: 0.7fr 0.4fr 0.4fr 0.4fr;
  gap: 0.2rem;
}

.brainstorm-questions-container {
  display: flex;
  flex-direction: column;
  /* position: absolute;
  top: 0;
  left: 0;
  z-index: 10; */
  background-color: rgba(248, 249, 250, 0.95);
  border: 1px solid #e5e7eb;
  border-radius: 0.75rem;

  transition: all 200ms;
  backdrop-filter: blur(4px);
  padding: 1rem;
  gap: 1rem;
  width: 100%;
}

.questions-title {
  color: #4b83f0;
  font-size: 1.25rem;
  font-weight: 500;
  text-align: left;
}

.brainstorm-background-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
}

.brainstorm-interaction-container {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  gap: 0.75rem;
  width: 100%;
  /* position: absolute;
  right: 0;
  z-index: 10; */
  background-color: rgba(248, 249, 250, 0.95);
  border: 1px solid #e5e7eb;
  border-radius: 0.75rem;

  transition: all 200ms;
  backdrop-filter: blur(4px);
  margin-right: 0.75rem;
  padding: 1rem;
}

.brainstorm-open-close-container {
  background-color: rgba(248, 249, 250, 0.95);
  border: 1px solid #e5e7eb;
  border-radius: 0.75rem;

  transition: all 200ms;
  backdrop-filter: blur(4px);
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  /* margin-right: 0.50rem; */
  padding: 1rem;
}

.brainstorm-research-container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
  /* position: absolute;
  right: 0;
  top: 12rem;
  z-index: 10; */
  background-color: rgba(248, 249, 250, 0.95);
  border: 1px solid #e5e7eb;
  border-radius: 0.75rem;
  transition: all 200ms;
  backdrop-filter: blur(4px);
  /* padding: 1rem; */
  gap: 1rem;
  width: 100%;
}

.research-title {
  color: #4b83f0;
  font-size: 1.25rem;
  font-weight: 500;
}

.research-question {
  color: #4a5568;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.research-answer {
  color: #718096;
  margin-bottom: 0.5rem;
}

.research-source {
  color: #a0aec0;
  font-style: italic;
  text-align: right;
  font-size: 0.875rem;
}

.research-link {
  color: #4b83f0;
  text-decoration: none;
  font-weight: 500;
}

.research-link:hover {
  text-decoration: underline;
}

.brainstorm-start-button-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.menu-arrow {
  position: absolute;
  top: 15px;
  right: 20%;
  z-index: 10;
  cursor: pointer;
}

.menu-arrow:hover {
  opacity: 0.8;
}

.menu-min-container {
  position: absolute;
  top: 15px;
  z-index: 10;
  background-color: rgba(248, 249, 250, 0.95);
  border: 1px solid #e5e7eb;
  border-radius: 0.75rem;

  transition: all 200ms;
  backdrop-filter: blur(4px);
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  /* margin-right: 0.50rem; */
  padding: 1rem;
}

.brainstorm-add-topic-container {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  /* background-color: #f3f4f6; */
  background-color: white;
  border: 1px solid #4b83f0;
  color: #4b83f0;
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.brainstorm-add-topic-container:hover {
  background-color: #e5e7eb;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.brainstorm-add-topic-text {
  width: max-content;
  font-size: 0.875rem;
  font-weight: 500;
  color: #4b83f0;
}

.fitted-button {
  background-color: #ffffff;
  border-radius: 0.375rem;
  transition: all 0.2s ease;
}

.fitted-button:hover {
  background-color: #f3f4f6;
}
